/* Estilos para el navbar */


body {


  margin: 0;
  padding: 0;
  font-family: "Montserrat";

  background-image: url( '../src/img/paginafondo.webp');
  background-size: cover; /* Ajusta el tamaño de la imagen para cubrir todo el fondo */
  background-repeat: no-repeat; /* Evita que la imagen de fondo se repita */
  background-attachment: fixed; /* Fija la imagen de fondo para que permanezca visible al hacer scroll */

  
} 


h1{
  font-family: "Outfit";
}
li{
  list-style: none;

}



/* Estilos para el contenido */
.content {
  background-color: rgba(255, 255, 255, 0.6); /* Fondo blanco semi-transparente para el contenido */
  padding: 3rem 0;
  text-align: center;
}

nav ul .navbar{
  list-style: none;
  display: flex;
  justify-content: center;
  background-color: #03318c;
  padding: 1rem 0;
  border-bottom: 3px solid #f20519;
  border-top: 3px solid #f20519;
  position: fixed; /* Fija el navbar en la parte superior de la página */
  width: 100%; /* Ocupa todo el ancho */
  z-index: 1000; /* Asegura que el navbar esté en la capa superior */
}

nav li {
  margin: 0 1rem;
}

/* Estilos para el enlace activo */
nav a.active {
  color: #f20519; /* Cambia el color del enlace activo */
}

/* Estilos para el enlace */
nav a {
  text-decoration: none;
  color: white;
}

/* Estilos para la imagen de fondo */
/*.hero 
/* Agrega aquí los nombres de las demás secciones */
/*{
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 6rem 0;
  color: rgb(252, 247, 247);
  font-size: 3rem;
  opacity: 0; /* Inicialmente, ocultar la sección */
  /* animation: entrada 6s forwards; /* Aplicar la animación de entrada */
  
/* } */


/* Estilos para el hero */
/* .hero {
  overflow: hidden;
  text-align: center;
  height: 200px; /* Ajusta la altura a 400px */
 /* padding: 10rem 0; /* Aumenta el padding vertical */
  /*color: #03318c; 
  font-size: 4rem; /* Aumenta el tamaño de fuente */
  /* -webkit-text-stroke: 2px red; /* Borde de texto rojo */
  /* text-stroke: 2px red; /* Borde de texto rojo */
  /* text-fill-color: red; /* Relleno de texto transparente para mostrar el borde */
  /* opacity: 0;
  animation: entrada 6s forwards;
/* }  

/* Estilos para el hero en dispositivos móviles */
/*@media screen and (max-width: 768px) {
  .hero {
    /*overflow: hidden;
    height: 300px; /* Ajusta la altura a 300px para dispositivos móviles */
    /*padding: 6rem 0; /* Ajusta el padding vertical para dispositivos móviles */
    /*font-size: 3rem; /* Ajusta el tamaño de fuente para dispositivos móviles */
    /*-webkit-text-stroke: 0px rgb(0, 94, 255); /* Borde de texto rojo */
    /*text-stroke: 2px rgb(254, 254, 254); /* Borde de texto rojo */
    /*text-fill-color: rgb(249, 249, 254); /* Relleno de texto transparente para mostrar el borde */
    /*opacity: 0;
    animation: entrada 6s forwards;
    justify-content: initial;
  }
} 


/* Resto de tus estilos para el hero */


@keyframes entrada {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Estilos para la imagen de fondo */
/*.hero::before {
 /*  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background-image: url('img/portadaescuela.png');   /*Ruta de la imagen Ajusta la imagen para cubrir el contenedor */
  /* background-size: cover;                             
  z-index: -1; 
}

.texto-portada{
   display: none;
  font-size: 40px; 
}

/* Media query para pantallas de celular */
 /*@media (max-width: 767px) {
   .hero:before { 
     background-image: url('img/celularfondo.jpg'); /* Nueva imagen de fondo para celular */
     /* background-position: right; /* Mover la imagen hacia la derecha en celular */
    
  /* }
  
}

  



/* Estilos para el contenido */
.content {
  padding: 3rem 0;
  text-align: center;
}

/* Estilos para el footer */
footer {
  background-color: #3032b1;
  color: white;
  text-align: center;
  padding: 1rem 0;
  border: 6px solid rgb(209, 32, 32);
  border: 6px solid rgb(216, 60, 60);
}

/* Estilos para las tarjetas */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.card {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  padding: 20px;
  width: calc(33.33% - 20px);
  box-sizing: border-box;
  margin-bottom: 20px;
  border-radius: 20px; /* Ajusta este valor para cambiar la curvatura de los bordes */
  transition: transform 0.2s, box-shadow 0.2s; /* Agregamos una transición suave */
}

.card:hover {
  transform: translateY(-15px); /* Cambia la posición en el eje Y al hacer hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Cambia la sombra al hacer hover */
}

.card:active {
  transform: translateY(0); /* Vuelve a la posición original al hacer clic */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Restaura la sombra al hacer clic */
}

h3 {
  margin-top: 0;
}

/* Estilos para imágenes y otros elementos */
.image-container {
  margin-top: 20px;
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
}

/* Media query para diseño responsive */
@media (max-width: 768px) {

  li{
    list-style: none;

  }
  .hero{
    z-index: -2;

  }
  .card {
    width: calc(50% - 20px);
  }
}

/* Media query para diseño responsive */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column; /* Cambia la dirección del flexbox a columna */
    align-items: center; /* Centra las tarjetas horizontalmente */
  }

  .hamburguesa{
    display: block; /* Muestra el navbar para dispositivos móviles en todo momento */

  }
  
  .texto-portada{
    display: block;
  }

  .card {
    width: 100%; /* Ocupa todo el ancho disponible */
  }

  .card.expanded {
    width: 90%; /* Ancho ampliado para la tarjeta expandida */
    margin: 0 auto; /* Centra la tarjeta */
  }

  .card.expanded p {
    display: block; /* Muestra el contenido oculto en la tarjeta expandida */
  }

  footer {
    text-align: center;
  }

  p {
    text-align: center;
    padding-right: 20px;
  }

  img {
    text-align: center;
    margin: 0 auto; /* Centra la tarjeta */
  }

  h1 {
    text-align: center;
  }

  .hero {
    text-align: center;
    padding-right: 20px;
  }

  
  
}
p {
  font-size: 25px;
}

/* Animación de carga con desvanecido */
@keyframes entrada {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
 
/* Estilos para el botón flotante */
.btn-flotante {
  position: fixed;
  bottom: 30px;
  right: 20px;
  background-color: transparent;
  color: rgb(42, 28, 61);
  border: none;
  padding: 10px;
  font-size: 3px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1001;
  transition: background-color 0.3s, transform 0.3s;
}

.btn-flotante.hovered {
  background-color: #436eb8;
  transform: scale(1.3);
}

.logo-container{
  justify-content: space-evenly;
  height: 30px;
  width: 45px;
  align-items: start;
  
}
.logo-container img {
  width: 100%;
  padding: 10px;
  object-position:start;
  object-fit: cover;
  align-items: start;

}


/* Estilos para el contenedor de redes sociales */
.redes-sociales-container {
  display: flex;
  justify-content: center;
}

/* Estilos para los iconos de redes sociales */
.icon-container {
  margin: 0 10px; /* Espacio entre los iconos */
}




/* Estilos para el contenedor de redes sociales */
.redes-sociales-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Alinea verticalmente los iconos */
  margin-top: 20px; /* Ajusta el margen superior según tus necesidades */
}

/* Estilos para los iconos de redes sociales */
.icon-container {
  margin: 0 10px; /* Espacio entre los iconos */
}


/* Estilos para los iconos cuando se les hace hover */
.icon.hovered {
  transform: scale(1.1); /* Escala el icono al hacer hover */
  transition: transform 0.3s ease; /* Agrega una transición suave al hacer hover */
}

/* Estilos para los iconos de redes sociales */
.icon-container {
  display: inline-block; /* Mostrar los íconos en línea */
  margin: 0 10px; /* Espacio entre los iconos */
}
.events-component {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrar horizontalmente */
  justify-content: center; /* Centrar verticalmente */
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 10px;
}

.event-list {
  list-style: none;
  padding: 0;
  width: 100%; /* Opcional: para hacer que la lista ocupe todo el ancho disponible */
}

.event {
  display: flex;
  margin-bottom: 20px;
  width: 100%; /* Opcional: para hacer que los eventos ocupen todo el ancho disponible */
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.event-date {
  flex: 0 0 100px;
  background-color: #f20519; /* Color llamativo */
  color: white; /* Texto en color blanco */
  text-align: center;
  padding: 10px;
  border-radius: 5px 0 0 5px;
}

.event-details {
  flex: 1;
  padding-left: 15px;
}

.event h3 {
  margin-top: 0;
  color: #333; /* Color de texto para el título del evento */
}

.event p {
  margin-bottom: 0;
  color: #666; /* Color de texto para la descripción del evento */
}



/* Estilos generales para el componente Contacto */
.contacto-section {
  background-color: #f8f8f8;
  padding: 80px 0;
  text-align: center;
}

.contacto-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.contacto-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 40px;
}

.contacto-info {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 400px;
  font-size: 1.1rem;
  color: #444;
}

/* Estilos para el formulario de contacto */
.formulario-contacto {
  max-width: 500px;
  margin: 0 auto;
}

.formulario-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.textarea-field {
  resize: vertical;
  min-height: 150px;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.submit-button:hover {
  background-color: #03318c;
}
 h1, h2{
  font-family: "Outfit";
  font-weight: bold;
 }
 /* Estilos para el componente EventsComponent */

.events-component {
  text-align: center;
  padding: 20px;
}

.event-cards-container {
  display: flex;
  justify-content: flex-start; /* Cambia de 'center' a 'flex-start' para alinear los eventos de izquierda a derecha */
  flex-wrap: wrap; /* Esto permite que las tarjetas se envuelvan si no caben en la pantalla */
  gap: 20px; /* Agrega un espacio entre las tarjetas */
}

.event-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  background-color: #f7f7f7;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 300px; /* Ancho de la tarjeta */
  cursor: pointer;
}

.event-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.event-card .event-date {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.event-card .event-details h3 {
  cursor: pointer;
}

.event-card .event-description {
  display: none; /* Oculta la descripción del evento por defecto */
  margin-top: 10px;
}

.event-card.active .event-description {
  display: block; /* Muestra la descripción del evento cuando está activo */
}

.event-card .event-description p {
  margin: 10px 0;
}

/* Agrega más estilos según sea necesario */
/* DocumentList.css */
.document-list {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.document-list ul {
  list-style: none;
  padding: 0;
}

.document-list li {
  margin: 10px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.document-list li:hover {
  transform: scale(1.03);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.document-list a {
  text-decoration: none;
  color: #333;
}

.document-list a:hover {
  color: #007bff;
}


/* Agrega más estilos según sea necesario */
/* ListaUtiles.css */
.cursos-list {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.cursos-list ul {
  list-style: none;
  padding: 0;
}

.cursos-list li {
  margin: 10px 0;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: transform 0.2s, box-shadow 0.2s;
}

.cursos-list li:hover {
  transform: scale(1.03);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.cursos-list a {
  text-decoration: none;
  color: #333;
}

.cursos-list a:hover {
  color: #007bff;
}




/* En tu archivo CSS (App.css) */
.como-llegar-button {
  position: relative;
  background-color: #3d53cd;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.map-icon {
  position: absolute;
  right: -35px; /* Ajusta la distancia desde el borde derecho del botón */
  top: 50%; /* Alinea verticalmente el icono en el centro del botón */
  transform: translateY(-50%); /* Centra verticalmente el icono en relación al botón */
}

.como-llegar-button:hover {
  background-color: #df3c36;
  text-decoration: none;
}
body{
  font-weight: normal; /* Esto quita la negrita */
  
}
/* Estilo para el pie de página */
.footer {
  background-color: #285599;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Permite que las columnas se ajusten en dispositivos pequeños */
  border-top: 2px solid red; /* Línea superior de separación */
  height: 300px;
}

/* Estilo para la columna del logotipo */
.footer-column:nth-child(1) {
  flex: 1; /* Ocupa todo el espacio disponible */
  text-align: left;
  border-right: 2px solid white; /* Línea de separación a la derecha del logotipo */
  padding-right: 1rem;
}

.footer-logo {
  width: 100px; /* Ajusta el ancho del logotipo según tus necesidades */
}

/* Estilo para la columna de enlaces */
.footer-column:nth-child(2) {
  flex: 2; /* Ocupa el doble de espacio que las otras columnas */
  padding-left: 1rem;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
}

/* Estilo para la columna de redes sociales */
.footer-column:nth-child(3) {
  flex: 1; /* Ocupa menos espacio que la columna de enlaces */
  text-align: right;
  padding-left: 1rem;
  border-left: 2px solid white; /* Línea de separación a la izquierda de las redes sociales */
}

.footer-social {
  list-style: none;
  padding: 0;
}

.footer-social li {
  margin-bottom: 1rem; /* Espacio entre elementos de la lista */
}

.footer-social a {
  color: white;
  text-decoration: none;
}

/* Estilo para los enlaces en el pie de página */
.footer-links a {
  color: white;
  text-decoration: none;
}
 
/* Estilo para los iconos cuando se hace hover */
.icon:hover {
  color: white; /* Cambia el color del texto (si lo hubiera) al que desees */
  transform: scale(1.1); /* Cambia el tamaño del icono al hacer hover (opcional) */
  transition: background-color 0.3s, color 0.3s, transform 0.3s; /* Agrega transiciones suaves para los cambios */
}


.footer-social a:hover img {
  filter: brightness(1.2); /* Aumenta el brillo al hacer hover */
}




body, html {
  margin: 0;
  padding: 0;
}

/* Estilo para el fondo del Header */
.header {

   
    display: flex;
    justify-content: space-between;
    align-items: left;
    padding: 10px;
    color: white;
    background: url("img/barrasuperior.png");
    background-size: 100% 220px !important;
    background-repeat: no-repeat;
    background-position: top;
    height: 190px;
    position: relative;
    top: 0;
    left: 0;
    width: 99%;
 
  

  
  
}

/* Estilo para la columna de redes sociales */
.footer-column {
  display: flex;
  align-items: center;
  justify-content: center; /* Esto centrará horizontalmente los íconos */
}

/* Estilo para los íconos de redes sociales */
.footer-social {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column; /* Esto alineará los íconos de arriba hacia abajo */
  gap: 10px; /* Espacio entre los íconos, ajusta según sea necesario */
  align-items: center; /* Esto centrará verticalmente los íconos en la columna */
}

.footer-social li {
  display: inline;
}

/* Estilos para dispositivos móviles (ancho máximo de 768px) */
@media (max-width: 768px) {
  .header {
    background-image: url('img/header\ celular.png');
    background-size: 100%; /* Cambia el valor a lo que desees para ajustar el tamaño */


  }
}





/* Default styles for the footer */
.footer {
  background-image: url('img/fondofooter.png'); /* Replace with the actual path to your background image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right; /* Move the background to the right by default */
}

/* Media query for mobile screens (adjust the max-width as needed) */
@media (max-width: 768px) {
  .footer {
    background-image: url('img/fondo\ footer2.png'); /* Replace with the actual path to your mobile background image */
    background-position: left; /* Change background position for mobile view */
  }
}

@media (min-width: 1025px) {
  .header {
    padding: '20px'; 
  }

  .logo-text {
    fontSize: '40px';
  }

}
/* Media query para pantallas mayores a 1024px */
@media (min-width: 1024px) {
  .header {
    background-size: cover; /* La imagen se ajusta para cubrir todo el fondo */
    background-position: center; /* La imagen se centra */
    height: 200px; /* Puedes ajustar la altura según tus necesidades */
    /* Otros estilos específicos para pantallas más grandes */
  }
}


/* Estilos CSS para hacer los videos responsivos */

.videos-container {
  width: 100%;
}

.videos-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.video-item {
  margin: 10px;
  flex: 1 0 calc(33.33% - 20px); /* Para mostrar tres videos por fila */
  max-width: calc(33.33% - 20px);
}

@media screen and (max-width: 768px) {
  .video-item {
    flex: 1 0 calc(50% - 20px); /* Para mostrar dos videos por fila en pantallas más pequeñas */
    max-width: calc(50% - 20px);
  }
}

@media screen and (max-width: 480px) {
  .video-item {
    flex: 1 0 calc(100% - 20px); /* Para mostrar un video por fila en pantallas muy pequeñas */
    max-width: calc(100% - 20px);
  }
}

.video-item iframe {
  width: 100%;
}

/* Estilos CSS para las tarjetas */
.video-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px; /* Espacio entre las tarjetas */
}

.video-card {
  flex: 0 0 calc(25% - 20px); /* Tamaño reducido de las tarjetas y espacio entre ellas */
  max-width: calc(25% - 20px);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.video-card:hover {
  transform: translateY(-5px); /* Efecto de elevación en hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Sombra en hover */
}

.video-card .card-content {
  background-color: #f9f9f9; /* Color de fondo de la tarjeta */
  padding: 16px;
}

.video-card h3 {
  margin-top: 0;
  font-size: 18px;
  color: #333; /* Color del texto del título */
}

.video-card .card-actions {
  margin-top: 12px;
}

.video-card .watch-video-btn {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff; /* Color del botón */
  color: #fff; /* Color del texto del botón */
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.video-card .watch-video-btn:hover {
  background-color: #0056b3; /* Color del botón en hover */
}

.videos-container {
  /* Estilos para el contenedor principal */
}

.videos-list {
  /* Estilos para la lista de videos */
}

.video-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.video-card {
  width: 250px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.video-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-content {
  /* Estilos para el contenido de la tarjeta */
}

h3 {
  margin-bottom: 10px;
  color: #333;
}

.card-actions {
  display: flex;
  justify-content: center;
}

.watch-video-btn {
  padding: 8px 16px;
  text-decoration: none;
  color: #fff;
  background-color: #ff5a5f;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.watch-video-btn:hover {
  background-color: #ff7b7f;
}
/* Tu CSS existente */

.channel-link {
  text-align: center;
  margin-top: 20px;
}

.channel-link-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.channel-link-btn:hover {
  background-color: #2980b9;
}



/* CSS para centrar el título de los videos */
.video-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  
}

.video-card {
  width: 90%; /* Cambia el ancho al que desees, por ejemplo, 90% del contenedor */
  max-width: 500px; /* Establece un ancho máximo para mantener el tamaño en pantallas más grandes */
  padding: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}


.video-card h3 {
  
  text-align: center;
  margin-bottom: 20px;
}

.card-actions {
  margin-top: 20px;
}

.card-actions .watch-video-btn {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.card-actions .watch-video-btn:hover {
  background-color: #0056b3;
}
/* CSS para centrar horizontalmente el título "Videos Escuela" */
.videos-container h2 {
  text-align: center;
  margin-bottom: 20px;
}



/* Estilos para el botón del canal */
.channel-link-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff0000; /* Color de fondo del botón */
  color: #ffffff; /* Color del texto */
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease; /* Transición suave del color de fondo */
  border: none;
  font-size: 16px;
  font-weight: bold;
}

/* Estilos cuando se pasa el mouse por encima */
.channel-link-btn:hover {
  background-color: #cc0000; /* Cambia el color de fondo al pasar el mouse */
}

/* Estilos cuando se presiona el botón */
.channel-link-btn:active {
  background-color: #990000; /* Cambia el color de fondo al hacer click */
}


/* Estilos CSS para las tarjetas de proyectos */
.proyectos-container {
  text-align: center;
  padding: 20px;
}

.proyectos-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.proyecto-card {
  width: 250px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  transition: transform 0.3s ease-in-out;
}

.proyecto-card:hover {
  transform: translateY(-5px);
}

.proyecto-card h3 {
  color: #333;
  margin-bottom: 10px;
}

.proyecto-card p {
  color: #666;
  font-size: 14px;
}

/* CSS para centrar horizontalmente el título "Videos Escuela" */
.proyecto-container h2 {
  text-align: center;
  margin-bottom: 20px;
  padding: 50px; /* Agregar padding al título */

}

/* Estilo para el fondo azul transparente */
.galeria-title {
  background-color: rgba(128, 128, 128, 0.7); /* Gris con 50% de transparencia */
  padding: 10px;
  border-radius: 5px;
  /*color: white; /* Color blanco para el texto */
  color: black; /* Color azul para el texto */
  text-align: center; /* Alinear el texto al centro */
  width: fit-content; /* Ajustar el ancho al contenido */
  margin: 0 auto; /* Centrar horizontalmente */
}


.logo img {
  width: 100%; /* Achica la imagen al 50% de su tamaño original */
  height: auto; /* Mantiene la proporción de la imagen */
  display: block; /* Asegura que la imagen sea tratada como un bloque para centrarla fácilmente */
  margin: 0 auto; /* Centra la imagen horizontalmente si es necesario */
}



/* .carousel-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Alineación superior */
 /* height: 400px; /* Altura del contenedor */
 /* overflow: hidden; /* Oculta cualquier contenido desbordado */
 /* margin: 0 auto; /* Centra el contenedor */
/* } */

/*.carousel .slide {
  position: relative; /* Para la alineación de las imágenes */
  /*height: 100%; /* Asegura que el slide ocupe el contenedor */
/* } */

/* .carousel .slide img {
  object-fit: contain; /* Mantiene la relación de aspecto */
 /* width: 100%; /* Ajusta al ancho del contenedor */
 /* height: auto; /* Altura automática para mantener la proporción */
 /* position: absolute; /* Posiciona las imágenes dentro del slide */
 /* top: 0; /* Alinea la imagen en la parte superior */
 /* left: 50%; /* Centra la imagen horizontalmente */
 /* transform: translateX(-50%); /* Compensa el desplazamiento al centrar */
/* } */



@media (max-width: 768px) {
  .hamburguesa {
    display: block;
  }

  .menu {
    display: none;
    position: absolute;
    top: 200px;
    right: 0;
    background-color: #285599;
    width: 100%;
    text-align: center;
    z-index: 1000; /* Añade un z-index alto para asegurarte de que esté por encima */
  }

  .menu.abierto {
    display: flex;
    flex-direction: column;
  }

  .menu li {
    margin: 1rem 0;
  }

  .dropdown:hover .submenu {
    display: block;
    opacity: 1;
  }
}




/* instagram cuadros  */
.Instagram-Galleria {
  padding: 20px;
}

.photos-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.photo-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.photo-card {
  width: 220px;
  height: 220px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background-color: #f0f0f0; /* Fallback si no hay imagen */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.photo-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.photo-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  text-decoration: none;
}


.gallery-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #333; /* Cambia el color según tu diseño */
}





/* Youtube videos */

.youtube-video-card {
  width: 380px;
  height: 200px;
  margin: 20px;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.youtube-video-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.youtube-overlay {
  background-color: rgba(245, 245, 245, 0.486); /* Fondo oscuro para contraste */
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
  color: #01010f2c !important; /* Forzamos el color blanco para el texto */
  font-size: 16px; /* Tamaño del texto para mejor visibilidad */
  font-weight: bold;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.10); /* Sombra para mejorar la legibilidad */
  transition: opacity 0.3s;
  opacity: 1;
}

.youtube-video-card:hover .youtube-overlay {
  opacity: 1; /* Aumenta la visibilidad al hacer hover */
}

.youtube-videos-container {
  padding: 20px;
}

.youtube-videos-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.youtube-channel-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.youtube-channel-logo {
  width: 100px;
  height: auto;
}

.youtube-channel-link-btn {
  padding: 10px 20px;
  background-color: #285599;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.youtube-channel-link-btn:hover {
  background-color: #003366;
}


.youtube-galeria-title {
  background-color: rgba(128, 128, 128, 0.7); /* Gris con 50% de transparencia */
  padding: 10px;
  border-radius: 5px;
  /*color: white; /* Color blanco para el texto */
  color: black; /* Color azul para el texto */
  text-align: center; /* Alinear el texto al centro */
  width: fit-content; /* Ajustar el ancho al contenido */
  margin: 0 auto; /* Centrar horizontalmente */
}     





iframe {
  width: 100%; /* El iframe ocupará todo el ancho del contenedor */
  height: auto; /* Ajusta la altura automáticamente para mantener las proporciones */
  aspect-ratio: 16 / 9; /* Establece una relación de aspecto para mantener proporciones */
}

/* Asegúrate de centrar el iframe en pantallas pequeñas */
@media (max-width: 768px) {
  iframe {
    max-width: 100%;
  }
}